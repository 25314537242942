import { useEffect, useRef } from 'react';
import { classList } from '~/utilities/css';

type Props = {
	message?: string;
	position?: 'top' | 'center',
};

/**
 * To use `OverlayContent` you need a mechanism like `useState()` to add/remove
 * the element based on some state.
 */
function OverlayContent({ message = 'One moment...', position = 'center' }: Props) {
	const ref = useRef<HTMLParagraphElement>(null);

	useEffect(() => {
		if (!ref.current) return undefined;

		// Find the closest parent that is scrollable
		const parent = ref.current.parentElement;
		if (!parent) return undefined;

		// Store original overflow to restore later
		const originalOverflow = parent.style.overflow;
		const originalPositioning = parent.style.position;

		// Apply new style
		parent.style.overflow = 'hidden';
		parent.style.position = 'relative';

		return () => {
			// Restore original styles
			parent.style.overflow = originalOverflow;
			parent.style.position = originalPositioning;
		};
	}, []);

	return (
		<p
			ref={ref}
			className={classList([
				'uic-absolute uic-inset-[0] uic-z-10',
				'uic-m-auto uic-w-full uic-h-full',
				'uic-bg-white/95 uic-font-quicksand uic-font-semibold',
				'uic-flex uic-flex-col uic-items-center',
				`${position === 'center' ? 'uic-justify-center' : ''}`,
				`${position === 'top' ? 'uic-justify-start uic-pt-30' : ''}`,
			])}
		>
			<span className="uic-text-sm">{message}</span>
		</p>
	);
}

export { OverlayContent as default, OverlayContent };
